<script setup lang="ts">
import { useCmsBlock } from "#imports";
import type {CmsBlockMetrics} from "~/types/cmsBlockTypes";

const props = defineProps<{
  content: any;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const brandsContent = getSlotContent("brandsSlot");

</script>

<template>
  <CmsGenericElement :content="brandsContent" />
</template>

<style scoped>

</style>
